<template>
    <div>
        <!-- <card-header :header="headers"></card-header> -->
        <!-- <div class="mb-2" v-if="!isActionnedNewRfc"> -->
        <div class="mb-2">
            <!-- <v-card-text>
                <div class="d-flex flex-row justify-space-between flex-wrap">
                    <h3>Request for proposals</h3>
                    <div class="">
                        <v-btn color="secondary" @click="createNewRequest">
                            <v-icon>{{ icons.mdiShapeRectanglePlus }}</v-icon>
                            <span>New RFP</span>
                        </v-btn>
                    </div>
                </div>
            </v-card-text> -->
            <div class="dash-vtab">
                <v-tabs v-model="tabe" hide-slider elevation="0" v-if="navadress === 'dashc'" class="dash-vtab-model">
                    <v-tab v-for="(tab, index) in tabsc" :key="index" :to="tab.link" class="tab-style"
                        active-class='tab-style-active'>
                        {{ tab.name }}
                    </v-tab>
                </v-tabs>

                <v-tabs v-model="tabe" hide-slider elevation="0" v-if="navadress === 'dashu'" class="dash-vtab-model">
                    <v-tab v-for="(tab, index) in tabsu" :key="index" :to="tab.link" class="tab-style"
                        active-class='tab-style-active'>
                        {{ tab.name }}
                    </v-tab>
                </v-tabs>
            </div>
        </div>
        <v-card elevation="0" class="rounded-lg" rounded>
            <router-view class="rounded-lg">
            </router-view>
            <!-- <v-tabs-items v-model="tabe">
            <v-tab-item :transition="false">
                <inbox :owner="navadress"></inbox>
            </v-tab-item>

            <v-tab-item :transition="false">
                <outbox :owner="navadress"></outbox>
            </v-tab-item>

            <v-tab-item :transition="false">
                <new-r-f-p></new-r-f-p>
            </v-tab-item>
        </v-tabs-items> -->
        </v-card>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import Inbox from './rfp/Inbox.vue'
import Outbox from './rfp/Outbox.vue'
import NewRFP from './rfp/NewRFP.vue'
import { mdiShapeRectanglePlus, mdiKeyboardBackspace } from '@mdi/js'
import CardHeader from '@/views/dashboard/components/CardHeader.vue'

export default {
    components: {
        Inbox,
        Outbox,
        NewRFP,
        CardHeader
    },
    data() {
        return {
            headers: {
                titlepage: 'REQUESTS FOR PROPOSALS',
                icon: null,
            },
            loading_rfp_list: true,
            myrfpdata: [],
            tabe: '',
            tabsc: [
                { name: "Received RFP", link: { name: 'company.rfp.inbox' } },
                { name: "Sent RFP", link: { name: 'company.rfp.outbox' } },
                { name: "Create RFP", link: { name: 'company.rfp.new' } },
            ],
            navadress: ((this.$route.path).substr(0, "/dashc".length).toUpperCase() === "/dashc".toUpperCase()) ? 'dashc' : 'dashu',
        }
    },
    watch: {
        $route: {
            handler: function (newRouteValue) {
                let company = "/dashc"
                let professional = "/dashu"
                if ((this.$route.path).substr(0, company.length).toUpperCase() === company.toUpperCase()) {
                    this.navadress = 'dashc'
                }
                if ((this.$route.path).substr(0, professional.length).toUpperCase() === "/dashu".toUpperCase()) {
                    this.navadress = 'dashu'
                }
                if ((this.$route.path).substr(this.$route.path.length - 3, this.$route.path.length).toLowerCase() === 'new') {
                    this.isActionnedNewRfc = true
                }
                else {
                    this.isActionnedNewRfc = false
                }
            }
        }
    },
    beforeMount() {
        this.checkRouterNew()
    },
    methods: {
        checkRouterNew() {
            if ((this.$route.path).substr(this.$route.path.length - 3, this.$route.path.length).toLowerCase() === 'new') {
                this.isActionnedNewRfc = true
            }
            else {
                this.isActionnedNewRfc = false
            }
        },
        createNewRequest() {
            this.isActionnedNewRfc = true
            if (this.navadress === 'dashc') {
                this.$router.push({ name: 'company.rfc.new', params: { company: this.$store.getters.getCompany.name } })
            }
            else {
                this.$router.push({ name: 'user.rfc.new' })
            }
        }
    },
    setup(props) {
        
        const icons = { mdiShapeRectanglePlus, mdiKeyboardBackspace }
        return {
            icons
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/tabs.scss';


.avatar-center {
    top: -50%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
</style>
